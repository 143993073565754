import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomSnackbarService {
  public notification$: Subject<{
    message: string;
    type: 'info' | 'error' | 'warning';
    link?: string;
  }> = new Subject();
  constructor() {}
}
