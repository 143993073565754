import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExpertiseOfficeRecordsService {
  private backendApiUrl: string = environment.backendApiUrl;
  refreshWorkOrders = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  getById(id: string): Observable<IExpertiseOfficeRecord> {
    const url = `${this.backendApiUrl}/api/expertise-office-records/${id}`;
    return this.http.get<IExpertiseOfficeRecord>(url, {});
  }

  search(
    filter: string,
    sortField: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number,
  ): Observable<IPagedResult<IExpertiseOfficeRecord>> {
    const url = `${this.backendApiUrl}/api/expertise-office-records`;
    return this.http.get<IPagedResult<IExpertiseOfficeRecord>>(url, {
      params: {
        filter,
        sortField,
        sortDirection,
        pageIndex: pageIndex.toString(),
        pageSize: pageSize.toString(),
      },
    });
  }

  create(data: IExpertiseOfficeRecord): Observable<IExpertiseOfficeRecord> {
    const url = `${this.backendApiUrl}/api/expertise-office-records`;
    return this.http.post<IExpertiseOfficeRecord>(url, data, {});
  }

  update(data: IExpertiseOfficeRecord): Observable<IExpertiseOfficeRecord> {
    const url = `${this.backendApiUrl}/api/expertise-office-records/${data.id}`;
    return this.http.put<IExpertiseOfficeRecord>(url, data, {});
  }

  delete(id: string): Observable<void> {
    const url = `${this.backendApiUrl}/api/expertise-office-records/${id}`;
    return this.http.delete<void>(url);
  }
}
