import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptorService implements HttpInterceptor {
  constructor() {}
  /*
   * Every outgoing Http request will go through this Http interceptor and append
   * Authorization with token to it.
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('api/users') && req.method === 'POST') {
      return next.handle(req);
    }
    /* Firebase will refresh token automatically if expired */
    return from(this.getCurrentIdToken()).pipe(
      mergeMap((token) => {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
        return next.handle(req);
      }),
    );
  }

  getCurrentIdToken() {
    return new Promise((resolve, reject) => {
      const auth = firebase.auth();
      const unsubscribe = auth.onIdTokenChanged((user) => {
        unsubscribe();
        if (user) {
          user.getIdToken().then((token) => {
            resolve(token);
          });
        } else {
          reject(null);
        }
      }, reject);
    });
  }
}
