import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private router: Router, private auth: AngularFireAuth) {}

  async login(email: string, password: string): Promise<any> {
    return this.auth
      .signInWithEmailAndPassword(email, password)
      .then((user: firebase.auth.UserCredential) => {
        const uData = JSON.stringify(user);
        return Promise.resolve(user);
      })
      .catch((error: any) => {
        console.log('login error', error);
        return Promise.reject(error);
      });
  }

  async logout() {
    this.router.navigate(['inloggen']);
    await this.auth.signOut();
  }
}
