<div class="footerContainer d-flex justify-content-between">
  <div class="ml-3">
    LekdetectieZuid is onderdeel van Installatiegroep Zuid BV.
    <br />
    VCA-gecertificeerd en aangesloten bij de Stichting Erkende
    Installatiebedrijven SEI.
  </div>
  <div class="mr-3">
    <table>
      <tr>
        <td>BTW</td>
        <td>NL858216437B01</td>
      </tr>
      <tr>
        <td>KvK</td>
        <td>70253374</td>
      </tr>
      <tr>
        <td>IBAN</td>
        <td>NL35RABO0325466726</td>
      </tr>
    </table>
  </div>
</div>
