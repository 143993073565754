export const environment = {
  production: true,
  backendApiUrl:
    'https://lekdetectiezuid-prod.eu-central-1.elasticbeanstalk.com',
  googleFireAuthSignUpUrl:
    'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=',
  googleFireAuthLoginUrl:
    'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=',
  googleFireApiKey: 'AIzaSyCAzuzBAi5uUZeZCEOkijWWnPSfTRWEKB0',
  firebaseConfig: {
    apiKey: 'AIzaSyCAzuzBAi5uUZeZCEOkijWWnPSfTRWEKB0',
    authDomain: 'lekdetectie-zuid-app.firebaseapp.com',
    databaseURL: 'https://lekdetectie-zuid-app.firebaseio.com',
    projectId: 'lekdetectie-zuid-app',
    storageBucket: 'lekdetectie-zuid-app.appspot.com',
    messagingSenderId: '100802947856',
    appId: '1:100802947856:web:3c5171a6e67387e2799395',
  },
  awsBucketName: 'lekdetectie-prod-files',
  awsDefaultRegion: 'eu-central-1',
};
