import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../auth/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private authStatusSubs$: Subscription;
  isAuthenticated = false;
  canMaintainEntities = false;
  canEditPlanning = false;
  onDestroy$ = new Subject();
  displayName: string;

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public auth: AngularFireAuth,
  ) {}

  async ngOnInit() {
    this.auth
      .onAuthStateChanged((user: firebase.User) => {
        if (user === null) {
          this.isAuthenticated = false;
          this.displayName = 'Niet ingelogd';
          this.canEditPlanning = false;
          this.canMaintainEntities = false;
        } else {
          this.isAuthenticated = true;
          this.displayName = user.displayName;
          user.getIdTokenResult().then((value: firebase.auth.IdTokenResult) => {
            if (value.claims.role === 'backoffice') {
              this.canEditPlanning = true;
              this.canMaintainEntities = true;
            } else {
              this.canEditPlanning = false;
              this.canMaintainEntities = false;
            }
          });
        }
      })
      .catch((reason: any) => {
        this.isAuthenticated = false;
        this.displayName = 'Niet ingelogd';
        this.canEditPlanning = false;
        this.canMaintainEntities = false;
        console.log('auth changed error', reason);
      });
  }

  ngOnDestroy() {
    if (this.authStatusSubs$) {
      this.authStatusSubs$.unsubscribe();
    }
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  createWorkOrder() {
    this.router.navigate([`./planning/werkorder-aanmaken`], {
      relativeTo: this.activatedRoute,
      queryParams: { planningTab: 'nieuw' },
    });
  }

  isRouteActive(path: string): boolean {
    const currentPath = this.activatedRoute.snapshot?.firstChild?.routeConfig
      ?.path;
    return currentPath === path;
  }

  onLogout() {
    this.authService.logout();
  }
}
