import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomSnackbarService } from './shared/services/custom-snackbar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject();

  constructor(
    private snackBar: MatSnackBar,
    private customSnackbarService: CustomSnackbarService,
  ) {}

  ngOnInit() {
    this.customSnackbarService.notification$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (notification: {
          message: string;
          type: 'info' | 'error' | 'warning';
        }) => {
          this.openSnackBar(notification.message, notification.type);
        },
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private openSnackBar(
    message: string,
    type: 'info' | 'error' | 'warning' = 'info',
  ) {
    const snackBarOptions: MatSnackBarConfig = {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: type,
    };
    if (type === 'error') {
      delete snackBarOptions.duration;
    }
    this.snackBar
      .open(message, type === 'error' ? 'Sluiten' : '', snackBarOptions)
      .onAction()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {});
  }
}
