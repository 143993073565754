<mat-toolbar color="primary">
  <span>
    <a [routerLink]="['/']"
      ><img
        class="logo"
        src="./../../assets/images/logo-install-zuid-budel.png"
        alt=""
        border="0"
      />
    </a>
  </span>
  <span class="spacer"></span>
  <div class="d-none d-print-block contactData">
    <table>
      <tr>
        <td>'t Inne 2c</td>
        <td class="blue">T</td>
        <td>+31(0)495 - 499512</td>
      </tr>
      <tr>
        <td>6021 DA&nbsp;&nbsp;Budel</td>
        <td class="blue">E</td>
        <td>info@lekdetectiezuid.nl</td>
      </tr>
      <tr>
        <td>Nederland</td>
        <td class="blue">I</td>
        <td>www.lekdetectiezuid.nl</td>
      </tr>
    </table>
  </div>
  <ul class="d-print-none">
    <li *ngIf="canEditPlanning">
      <a mat-raised-button color="primary" (click)="createWorkOrder()"
        >Nieuwe werkorder</a
      >
    </li>
    <li *ngIf="canMaintainEntities && !isRouteActive('beheer')">
      <a mat-raised-button color="secondary" [routerLink]="['beheer']"
        >Beheer</a
      >
    </li>
    <li *ngIf="canEditPlanning && !isRouteActive('planning')">
      <a mat-raised-button [routerLink]="['planning']">Planning</a>
    </li>
  </ul>
  <span class="d-print-none displayname"> {{ displayName }} </span>

  <button
    class="ml-3 d-print-none"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    aria-label="Example icon-button with a menu"
  >
    <mat-icon class="icon-color">more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <div>
      <button mat-menu-item (click)="onLogout()">
        <mat-icon>logout</mat-icon>
        <span>Uitloggen</span>
      </button>
    </div>
    <ng-template #showLogin>
      <button mat-menu-item routerLink="/inloggen">
        <mat-icon>login</mat-icon>
        <span>Inloggen</span>
      </button>
    </ng-template>
  </mat-menu>
  <ng-template #showLoginButton>
    <button mat-menu-item routerLink="/inloggen">
      <mat-icon>login</mat-icon>
      <span>Inloggen</span>
    </button>
  </ng-template>
</mat-toolbar>
