import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './navigation/header/header.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ConfirmComponent } from './shared/components/confirm/confirm.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { RequestInterceptorService } from './shared/token-interceptor';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { FooterComponent } from './shared/footer/footer.component';
import { ClientRecordsService } from './shared/services/client-records.service';
import { ExpertiseOfficeRecordsService } from './shared/services/expertise-office-records.service';
import { CustomerRecordsService } from './shared/services/customer-records.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageNotFoundComponent,
    FooterComponent,
    ConfirmComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
    ClientRecordsService,
    CustomerRecordsService,
    ExpertiseOfficeRecordsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
