<mat-card>
  <mat-card-title class="center-content">
    Sorry... pagina niet gevonden
    <div>
      <button mat-raised-button color="primary" [routerLink]="['/planning']">
        Terug
      </button>
    </div>
  </mat-card-title>
</mat-card>
