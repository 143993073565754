import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { canActivate, customClaims } from '@angular/fire/auth-guard';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

const backoffice = (next) =>
  pipe(
    customClaims,
    map((claims) => claims.role === 'backoffice'),
  );

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inloggen',
    pathMatch: 'full',
  },
  {
    path: 'planning',
    ...canActivate(backoffice),
    loadChildren: () =>
      import('./work-order/work-order.module').then((m) => m.WorkOrderModule),
  },
  {
    path: 'beheer',
    ...canActivate(backoffice),
    loadChildren: () =>
      import('./maintenance/maintenance.module').then(
        (m) => m.MaintenanceModule,
      ),
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
